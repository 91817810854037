import React, { useState, useEffect } from 'react'
import Jornallistbyuser from './components/journalmanagement/jornallistbyuser'
import FetchApi from '../customhooks/functionFetchApi'
import Spinner from 'react-bootstrap/Spinner';
import { FaList, FaFileSignature, FaArchive, FaExclamationTriangle, FaCheck } from "react-icons/fa";
import { Button } from 'react-bootstrap';


const Journalmanagement = () => {
  const FetchApis = new FetchApi();
  const [datajournal, setDatajournal] = useState([]);
  const [dataList, setDataList] = useState([])
  const [jourStatusAll, setJourStatusAll] = useState([]);
  const emcode = sessionStorage.getItem('EmCode');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadingdata();
    getJournalStatusAll();
  }, [])


  const loadingdata = () => {
    setLoading(true)
    setTimeout(() => {
      FetchApis.FethcGet(`/journal/getjournalByEmcode/${emcode}`).then((res) => {
        if (res) {
          setDatajournal(res.data.data);
          setDataList(res.data.data);
          console.log(res.data.data)
        }
        setLoading(false);
      })
    }, 1000);

  }

  function getJournalStatusAll() {
    FetchApis.FethcGet(`/journal/getJoranlstatusAll/${emcode}`).then((res) => {
      setJourStatusAll(res.data.data[0]);
    })
  }

  function heandleSearch(e) {
    const { value, name } = e.target;

    // setSearchInput(searchValue)
    const filteredData = datajournal.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
    })

    setDataList(filteredData)
  }

  function filterSuccesfull(e){
    setLoading(true)
    const filterData = datajournal.filter((item) => item.flowSuccesfull == '1');
    setDataList(filterData)

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }

  function filterNotSuccesfull(e){
    setLoading(true)
    const filterData = datajournal.filter((item) => item.flowSuccesfull == null);
   
    setDataList(filterData)

    setTimeout(() => {
      setLoading(false)
    }, 1000);
   
  }

  function allListData() {
    setLoading(true)
    setDataList(datajournal)

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }

  function cancelJournal(e){
    setLoading(true)
    const filterData = datajournal.filter((item) => item.statusflow == "0000");
   
    setDataList(filterData)

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }


  return (
    <div>
      <div>
        <div><center>
          <label style={{ fontSize: 20, margin: 5, padding: 5 }}><FaFileSignature /> เอกสารทั้งหมดของผู้ใช้งาน </label>
        </center>
        </div>
      </div>
      <div className='row-between-page' style={{ backgroundColor: '#E5E7E7' }}>

        <div className='row-page'>
          <div><label style={{ fontSize: 14, color: 'blue', margin: 5 }}><Button onClick={()=>allListData()}><FaList /> เอกสารทั้งหมด ( {jourStatusAll.journalall} )</Button></label></div>
          <div><label style={{ fontSize: 14, color: 'green', margin: 5 }}> <Button variant="success"  onClick={()=>filterSuccesfull()}><FaCheck /> อนุมัติแล้ว ( {jourStatusAll.approveall} )</Button></label></div>
          <div><label style={{ fontSize: 14, color: 'orange', margin: 5 }}><Button variant="warning" onClick={()=>filterNotSuccesfull()}><FaArchive /> รออนุมัติ ( {jourStatusAll.prendingall} )</Button></label></div>
          <div><label style={{ fontSize: 14, color: 'red', margin: 5 }}><Button variant="danger" onClick={()=>cancelJournal()}><FaExclamationTriangle /> ยกเลิก ( {jourStatusAll.recallall} )</Button></label></div>
        </div>

        <div><input type='text' name='searchAlll' placeholder='ค้นหาข้อมูล' onChange={(e) => heandleSearch(e)} /></div>
      </div>
      <div>
        {loading == true ? <div className='body-center-page'><Spinner
          as="span"
          animation="grow"
          variant="success"
          size={200}
          role="status"
          aria-hidden="true" /> LOADIN ....</div> : <div>
          <Jornallistbyuser datatable={dataList} />
        </div>}
      </div>
    </div>
  )
}
export default Journalmanagement