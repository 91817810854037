import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from 'react'
import Rdcreatenewpart from './rdcreatenewpartb';
import Rdcreateeditpart from './rdcreateeditpartb';
import FetchApi from '../../../customhooks/functionFetchApi';
import RdApproveState from './rdapproveState';
import { FaFilePdf, FaFileSignature, FaCheckCircle } from "react-icons/fa";

function Actionall({ journalId, codeid, stateflow }) {
    const FetchApis = new FetchApi();
    const [checkdata, setCheckdata] = useState(false);

    useEffect(() => {
        FetchApis.FethcGet(`/technical/techniaclByJournalId/${journalId}`).then((res) => {
            if (res.status == 200) {
                if (res.data.data.length > 0) {
                    setCheckdata(true)
                } else {
                    setCheckdata(false)
                }
            }
        })
    }, [])

    return (
        <div className='row-page'>
            {checkdata == false ? <Rdcreatenewpart datavalues={journalId} /> :
                <div className='row-page' >
                    <RdApproveState idjournal={journalId} stateflow={stateflow} />
                    <Rdcreateeditpart datavalues={journalId} codeid={codeid} stateflow={stateflow} /></div>
            }
        </div>
    )
}

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => <div>
            <div>{row.tdscode}</div>
            {row.salerunnumber != "" ? <div>({row.salerunnumber})</div> : <div></div>}</div>,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'product name',
        selector: row => row.productname,
    },
    {
        name: 'job status',
        selector: row => <div>{row.statusprove_Tds == 1 && <label style={{ color: '#0e9a07' }}><FaCheckCircle /> proof job</label>}</div>,
    },
    {
        name: 'confirm job',
        selector: row => <div>{row.statusConfirm == 1 && <label style={{ color: '#0e9a07' }}><FaCheckCircle />Approve</label>}</div>,
    },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    {
        name: 'Action',
        // grow: 1.5,
        cell: row => <Actionall journalId={row.journalId} codeid={row.tdscode} stateflow={row.stateflow} />
        ,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Journaltablenewpartb = (datatable) => {
    var dataresult = datatable.datatable;


    return (

        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Journaltablenewpartb