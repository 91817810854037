import DataTable from 'react-data-table-component';
import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaFileAlt,FaCheckCircle } from "react-icons/fa";

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => <div>
        <div>{row.tdscode}</div>
        {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}</div>,
        grow: 0.5,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
        grow: 0.7,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
        grow: 1,
    },
    // {
    //     name: 'product name',
    //     selector: row => row.productname,
    //     grow: 1.5,
    // },
    {
        name: 'revision',
        selector: row => row.revision,
        grow: 0.5,
    },
    {
        name: 'confirm job',
        selector: row =><div>{row.statusConfirm==1&&<label style={{color:'#0e9a07'}}><FaCheckCircle />Approve</label>}</div> ,
    },
    // {
    //     name: 'duedate',
    //     sortable: true,
    //     selector: row => row.duedate,
    //     grow: 0.5,
    // },
    {
        name: 'createdate',
        selector: row => row.createdate,
        grow: 0.5,
    },
    {
        name: 'Action',
        grow: 1,
        cell: row =><div> <Link to={`/journalapprove/${row.journalId}`}  ><Button size='sm'><FaFileAlt/>จัดการข้อมูล</Button></Link></div>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Journaltablebygroup = (datatable) => {
    var dataresult = datatable.datatable;


    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Journaltablebygroup