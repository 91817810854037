// import logo from './logo.svg';
// import './App.css';
import React, { useState, useEffect } from "react";
import {
  FaUserCircle,
  FaBell,
  FaShareSquare,
  FaExclamationCircle,
  FaArchive,
  FaClipboardCheck
} from "react-icons/fa";

import RoutePage from "./pages/RoutePage";
import FetchApi from "./pages/customhooks/functionFetchApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from "react-bootstrap";
import './pages/css/styledopdown.css'

function App() {
  const FetchApis = new FetchApi()
  var usernames = sessionStorage.getItem("UserName");
  var emcode = sessionStorage.getItem("EmCode");

  var isloging = sessionStorage.getItem("Islogin");

  const [userlogin, setUserlogin] = useState(isloging);
  const [username, setUsername] = useState(usernames);
  const [usercode, setUsercode] = useState(emcode);

  const [statusAll, setStatusAll] = useState({ preRecievd: 0, preReSend: 0, preReject: 0 })

  useEffect(() => {
    sessionStorage.getItem("Islogin");
    var usernames = sessionStorage.getItem("UserName");

    setUsername(usernames);

    var eccodelogin = sessionStorage.getItem("EmCode");

    loadingdatastatus(eccodelogin);

  }, [])


  const signout = () => {
    sessionStorage.setItem("Islogin", false);
    sessionStorage.setItem("UserName", null);
    sessionStorage.setItem("Token", null);
    sessionStorage.setItem("EmCode", null)

    window.location.reload();
    window.location.href = '/';


  }

  const signin = () => {
    window.location.href = '/login';
    window.location.reload();
  }

  function loadingdatastatus(ecodes) {
    // journal/getjouranlstatusAllByemcode/
    FetchApis.FethcGet(`/journal/getjouranlstatusAllByemcode/${ecodes}`).then((res) => {
      setStatusAll(res.data.data[0])
    })

  }

  return (
    <div>
      <ToastContainer />
    {userlogin !='true'?<div></div>:

    
      <div style={{ backgroundColor: '#EEEDEB' }} className="row-between-page header">
        <div style={{ padding: 5 }} className="row-page">
          <div><img src={require('./pages/images/30516.jpg')} style={{ width: 100, height: 'auto', borderRadius: 3 }} /></div>

          {userlogin == "true" ?
            <div style={{ marginLeft: 20 }}>
              
                <Button size="sm" variant="warning" style={{ marginRight: 2 }}>
                  <FaArchive /> เอกสารใหม่ : {statusAll.preRecievd}
                </Button>
       
                <Button size="sm" variant="primary" style={{ marginRight: 2 }}>
                  <FaClipboardCheck color="#ffff" /> เอกสารรออนุมัติ : {statusAll.preReSend}
                </Button>
                
                <Button size="sm" variant="danger" style={{ marginRight: 2 }}>
                <FaExclamationCircle color="#ffff" /> เอกสารตีกลับ : {statusAll.preReject}
              </Button>
            
            </div> : <div></div>
          }

        </div>

        <div style={{ padding: 5, width: 'auto' }} className="row-between-page">
          <div><FaBell size={20} color="#4A4B4B" /></div>
          <center>
            {username != "null" ? <div style={{ padding: 5, width: 110 }} className="dropdown">
              <div><a href="#"><FaUserCircle size={30} color={username != "null" ? "#308A52" : "#4A4B4B"} /></a></div>
              <div style={{ fontSize: 12 }}>{username != "null" ? username : ""}</div>

              <div class="dropdown-content">
                <div>สวัสดี คุณ : {username}</div>
                <div>{usercode}</div>
              </div>

            </div> : <div style={{ padding: 5, width: 110 }}><a href="#"><FaUserCircle size={30} color={username != "null" ? "#308A52" : "#4A4B4B"} /></a></div>}
          </center>
          <center>
            {userlogin == "true" ? <div>
              <div><a href="#"><FaShareSquare onClick={() => signout()} style={{ color: 'black' }} /></a></div>
              <div>
                <label style={{ fontSize: 12 }}> sign out</label></div>
            </div>
              :
              <div>
                <div><a href="/login"><FaShareSquare onClick={() => signin()} style={{ color: 'black' }} /></a></div>
                <div>
                  <label style={{ fontSize: 12 }}> sign in</label></div>
              </div>

            }
          </center>
        </div>
      </div>}

      <RoutePage />
    </div>
  );
}

export default App;
