import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FaFileSignature } from "react-icons/fa";
import EditformsHeaderjournal from './editformsHeaderjournal';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Detailrevision from './detailrevision';
import { createRevision_Duplicate } from './service/serviceCreaterevision';
import Swal from 'sweetalert2';
import FetchApi from '../../../customhooks/functionFetchApi';
import { ToastContainer, toast } from 'react-toastify';

function Actionrevision({ id, actionState, codetds, revision }) {
    const FetchApis = new FetchApi();
    const [journallist, setJournallist] = useState([])
    let revisionNew = parseInt(revision) + 1;

    // const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(values) {
        setShow(values);
    }

    const onClickNewRevision = () => {
        Swal.fire({
            title: "แจ้งเตือน",
            text: "คุณแน่ใจที่จะสร้างเอกสาร ใช่หรือไม่ ! ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {

            if (result.isConfirmed) {

                var dataobject = {
                    codetds: codetds,
                    revision: revisionNew.toString()
                }
                FetchApis.FethcPost(`/journal/getcheckRevisionJournal`, dataobject).then((res) => {
                    if (res.status == 200) {
                        if (res.data[0].countId == 0) {

                            // console.log(res.data[0])
                            toast.success('บันทึกเรียบร้อยแล้ว', {
                                position: "top-right",
                                autoClose: 1700,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                // transition: Bounce,
                              });

                            createRevision_Duplicate(id)
                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 1700);


                        }
                        else {
                            Swal.fire({
                                title: "แจ้งเตือน!",
                                text: `เอกสารนี้มีมี revision ${revisionNew.toString()} แล้ว กรุณาตรวจสอบอีกครั้ง`,
                                icon: "warning"
                            });
                            // console.log(res.data[0])

                        }
                    }
                    else {
                        console.log(res)
                    }

                })



            }
        });

        // createRevision_Duplicate(id);
        // console.log(resultcreate);
    }


    return (
        <div>
            <Button size='sm' style={{ fontSize: 12 }}
                onClick={() => handleShow(true)} variant="secondary" disabled={actionState}><FaFileSignature /> สร้าง revision</Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                // size="lg"
                fullscreen={fullscreen}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header >

                    <div style={{ width: '20%', fontSize: 18 }}>รายละเอียดเอกสาร</div>
                    <div className='end-flex-row '>
                        <Button size='sm' variant="success" style={{ marginRight: 10 }} onClick={() => onClickNewRevision()}>สร้าง revision</Button>
                        <Button variant="secondary" size='sm' onClick={() => setShow(false)}>Close</Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Detailrevision jourId={id} />
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default Actionrevision