import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FaList } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import FetchApi, { host } from '../../../customhooks/functionFetchApi';
import { FaCheckCircle } from "react-icons/fa";
import Swal from 'sweetalert2'

function FormConfrimeproduct({ id, handleShow, data }) {
    const FetchApis = new FetchApi();
    const [textDetail, setTextDetail] = useState(data.commentscomfirm);
    const [valueStatus, setValueStatus] = useState(data.statusConfirm);
    const [fileLoad, setFileLoad] = useState("")

    async function onComfirm() {

        if (fileLoad != "") {
            if (fileLoad.size / 1024 > 5000) {
                // alert("file size must not be greater than to 5000k")
                Swal.fire({
                    title: "แจ้งเตือน!",
                    text: "file size must not be greater than to 5000k",
                    icon: "warning"
                });

            } else {
                FetchApis.FetchUploadfile('/images/uploadfile', fileLoad).then(res => {
                    if (res.success == true) {

                        let obdata = {
                            statusConfirm: valueStatus,
                            fileproductConfirm: res.filename,
                            commentscomfirm: textDetail
                        }
                        // -->
                        FetchApis.FethcUpdate(`/journal/updateconfirmproduct/${id}`, obdata).then((res) => {
                            if (res.status == 200) {
                                // console.log('เรียนร้อยแล้ว')
                                Swal.fire({
                                    title: "แจ้งเตือน!",
                                    text: "บันทึกข้อมูล confirm product เรียบร้อยแล้ว",
                                    icon: "success"
                                }).then((result) => {
                                    window.location.reload();
                                    handleShow(false);
                                })
                            }
                        })

                    }
                })
            }
        } else {
            let obdata = {
                statusConfirm: valueStatus,
                fileproductConfirm: data.fileproductConfirm,
                commentscomfirm: textDetail
            }

            FetchApis.FethcUpdate(`/journal/updateconfirmproduct/${id}`, obdata).then((res) => {
                if (res.status == 200) {
                    // console.log('เรียนร้อยแล้ว')
                    Swal.fire({
                        title: "แจ้งเตือน!",
                        text: "บันทึกข้อมูล confirm product เรียบร้อยแล้ว",
                        icon: "success"
                    }).then((result) => {
                        window.location.reload();
                        handleShow(false);
                    })
                }
            })

        }
    }

    async function oncheckbox(e) {

        let checkbox = e.target.checked;
        const { name, value } = e.target;

        if (checkbox) {
            setValueStatus(value);
        } else {
            setValueStatus('0');
        }
    }

    async function onCancle(e) {
        handleShow(e)
    }

    const handleFile = event => {
        var input = document.getElementById('loadfilename');
        const file = input.files[0]
        // const size = file.size // it's in bytes
        if (file != undefined) {
            setFileLoad(input.files[0])
        } else {
            setFileLoad("")
        }
    }

    async function loadingfile(namefile) {
        // window.location.href(`${host}/images/files/${namefile}`)
        window.location.href = `${host}/images/files/${namefile}`;

        // FetchApis.FethcGet(`/images/files/${namefile}`).then((res)=>{
        //     if(res){
        //     }
        // })
    }




    return (<div>
        <div style={{ marginTop: 10, padding: 10 }}>
            <div  style={{backgroundColor:'#f7eadb',padding:10}}>
                <div><label><b>Product Name : </b></label> <label>{data.productname}</label></div>
                <div><label><b>Customer : </b></label><label>{data.customername}</label></div>
            </div>
            {data.statusConfirm == '1' ? <div>
                <input type='checkbox' value={'1'}
                    onChange={(e) => oncheckbox(e)}
                    defaultChecked={true} /> <b><label>confirm product approved</label></b>
            </div> : <div>
                <input type='checkbox' value={'1'}
                    onChange={(e) => oncheckbox(e)}
                    defaultChecked={false} /> <b><label>confirm product approved</label></b>
            </div>
            }

        </div>
        <div style={{ marginTop: 5, padding: 10 }}>
            <div><b><label>อธิบายรายละเอียด</label></b></div>
            <div><textarea value={textDetail} style={{ width: '100%', height: 100 }}
                placeholder='ป้อนข้อมูลรายละเอียด'
                onChange={(e) => setTextDetail(e.target.value)}
            /></div>
        </div>
        <div style={{ marginTop: 5, padding: 10 }}>
            <div><b><label>แนบไฟล์ตัวอย่าง สินค้า</label></b></div>
            <div><input type='file' name='loadfilename' accept="image/png, image/jpg,application/pdf" onChange={handleFile} id='loadfilename' /></div>
        </div>
        {data.fileproductConfirm != "" ? <div>
            <a href={`${host}/images/files/${data.fileproductConfirm}`}> <label>Click load file ล่าสุด</label></a>
        </div> : <div></div>}
        <center>
            <div>

                <Button onClick={() => onComfirm()} >บันทึก confirm</Button>{" "}
                <Button onClick={() => onCancle(false)} variant="warning" >ยกเลิก</Button>

            </div>
        </center>
    </div>)
}




const ActionConfirmproduct = ({ id, actionState }) => {
    const FetchApis = new FetchApi();

    // const [journallist, setJournallist] = useState([])

    // const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [journaldata, stetJournaldata] = useState([])


    useEffect(() => {
        FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((res) => {
            if (res.data.status == 200) {
                // console.log(res.data.data[0])
                stetJournaldata(res.data.data[0])
            }
        })
    }, [])




    function handleShow(e) {
        setShow(e);
    }

    return (
        <div>
            <Button size='sm' variant="success" style={{ fontSize: 12 }} disabled={actionState}
                onClick={() => handleShow(true)}><FaCheckCircle /> confirm Product</Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                // fullscreen={fullscreen}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        confirm approved product # {journaldata.tdscode}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormConfrimeproduct id={id} handleShow={handleShow} data={journaldata} />
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ActionConfirmproduct