import React, { useState, useEffect } from 'react'
import FetchApi from '../../../customhooks/functionFetchApi';
import Dropdown from 'react-bootstrap/Dropdown';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Spinner from 'react-bootstrap/Spinner';

const Flowuserapprove = (props) => {
    const FetchApis = new FetchApi();
    const [userlist, setUserlist] = useState([])
    const [show, setShow] = useState(false);
    const[loading,setLoading]=useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        
        setLoading(true)
        setTimeout(() => {
            // listAccount()
            setLoading(false)
        }, 1000);
       
    }, [props])

    function listAccount() {
        handleShow()

        // setLoading(true)
        var dataobject = {
            journalId: props.id,
            stateflow: props.stateflow
        }

        FetchApis.FethcPost(`/runflow/getRunflowUserApprove`, dataobject).then((res) => {
            setUserlist(res.data)
            setTimeout(() => {
                    // setLoading(false)
            }, 500);
        })
    }

    return (<>
  
        {props.accountapprove != null &&<div>
            {loading ==true?<div><Spinner animation="border" variant="info" /></div>:
            <Dropdown>
                <Dropdown.Toggle variant="warning" id="dropdown-basic" size='sm' onClick={()=>listAccount()}>
                    <label style={{ fontSize: 12 }}>{props.accountapprove + "(" + props.departments + ")"}</label>
                </Dropdown.Toggle>
            </Dropdown>}
            
            </div>}
        
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>รออนุมัติ แสดงรายชื่อผู้อนุมัติ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table style={{width:'100%'}}>
                        <tr>
                            <th>ชื่อ</th>
                            <th>แผนก</th>
                        </tr>
                        {userlist.map((item, i) => (

                            <tr>
                                <td>{item.approveby}</td>
                                <td>{item.department}</td>
                            </tr>

                        ))}
                    </table>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Flowuserapprove