import React from 'react'
import DataTable from 'react-data-table-component';
import Actiondedicate from './actiondedicate';

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => <div>
        <div>{row.tdscode}</div>
        {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}</div>
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'itemname',
        selector: row => row.itemname,
    },
    {
        name: 'revision',
        selector: row => row.revision,
    },
    {
        name: 'duedate',
        sortable: true,
        selector: row => row.duedate,
    },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    {
        name: 'Action',
        grow: 1.5,
        cell: row => <Actiondedicate idjournal={row.journalId} dedicateid={row.dedicatecode}/>,
    },

];


const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};
const Tablelistdedicate = ({values}) => {
     var data = values;
      
    return (
        <DataTable
            columns={columns}
            data={data}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover
        />
    )
}

export default Tablelistdedicate